<template>
  <b-card-code>
    <div v-if="$can('read', 'Mark Distribution')" class="custom-search d-flex">
      <router-link :to="{ path: '/mark/distribution/update' }">
        <b-button variant="outline-primary">
          {{ $t('Distribution List') }}
        </b-button>
      </router-link>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit="submitMarkDistribution" method="post" action="javascript:void(0)" ref="form_add_or_update"
        class="repeater-form">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Class') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select v-model.number="classes_id" class="form-control" name="classes_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('academic year')" rules="required">
                <select v-model.number="academic_year_id" class="form-control" name="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(acy, key) in academic_years" :key="key" :value="acy.id">{{ acy.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam type') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('exam type')" rules="required">
                <select v-model.number="exam_type_id" class="form-control" name="exam_type_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(etype, key) in exm_types" :key="key" :value="etype.id">{{ etype.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Row Loop -->
        <b-row v-for="(item, index) in subjectList" :id="item.id" :key="index" ref="row">

          <!-- Mark from -->
          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Subject Name') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('subject name')" rules="required">
                <b-form-input v-model.number="item.name" readonly :state="errors.length > 0 ? false : null"
                  :placeholder="$t('mark form')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Select Grade') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')">
                <select class="form-control" :id="item.id + '_grade'" :state="errors.length > 0 ? false : null">
                  <option :value="null" selected>Select Grade</option>
                  <option v-for="(grade, key) in grade_types" :key="key" :value="grade.id">{{ grade.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Select Mark Type') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mark type')" rules="">
                <b-form-checkbox v-for="(type, key) in mark_types" :key="key" :value="type" name="mark_type"
                  @change="addNewMarkType(item.id, type)" :state="errors.length > 0 ? false : null"
                  :placeholder="$t('mark type')">{{ type }}</b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>
          <b-row v-for="(inp, index2) in mark_distribution_array" :id="'ss' + inp.id" :key="index2" ref="row2"
            style="padding:15px">
            <template v-if="inp.subject_id === item.id">
              <b-col md="3">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Mark type') }} </span>
                  </b-card-text>
                  <validation-provider #default="{ errors }" :name="$t('mark type')" rules="required">
                    <b-form-input v-model.number="inp.name" readonly :state="errors.length > 0 ? false : null"
                      :placeholder="$t('mark type')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Total mark') }} </span>
                  </b-card-text>
                  <validation-provider #default="{ errors }" :name="$t('total mark')" rules="required">
                    <b-form-input v-model.number="inp.total_mark" :state="errors.length > 0 ? false : null"
                      :placeholder="$t('total mark')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Pass mark') }} </span>
                  </b-card-text>
                  <validation-provider #default="{ errors }" :name="$t('pass mark')" rules="required">
                    <b-form-input v-model.number="inp.pass_mark" :state="errors.length > 0 ? false : null"
                      :placeholder="$t('pass mark')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('To be added(TM×CM%)') }} </span>
                  </b-card-text>
                  <validation-provider #default="{ errors }" :name="$t('mark percent add')" rules="required">
                    <b-form-input v-model.number="inp.mark_percent_add" :state="errors.length > 0 ? false : null"
                      :placeholder="$t('mark percent add')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-row>
        <b-col>
          <b-button variant="primary" type="submit">
            {{ $t('Submit') }}
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,
  BForm, BRow, BCol, BCardText, BFormCheckbox
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment"
export default {
  name: 'MarkDistribution',
  components: {
    BCardCode,
    BFormGroup,
    BFormInput,
    BButton, BCardText,
    BForm, BRow, BCol, BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      classes_id: null,
      version_id: null,
      student_group_id: null,
      academic_year_id: null,
      exam_type_id: null,
      classes: [],
      versions: [],
      student_groups: [],
      required,
      dir: false,
      model_mode: 'add',
      selected_row: {},
      mark_types: [],
      mark_distribution_array: [],
      grade_id: null
    }
  },
  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
      return date;
    },
    selectedRow(row) {
      this.selected_row = row;
      this.name = row.name;
      this.items = JSON.parse(row.grades);
      this.model_mode = 'edit';
      this.$refs['my-modal'].show()
    },
    submitMarkDistribution() {
      if (this.mark_distribution_array.length == 0 || this.mark_distribution_array.length != (this.mark_types.length * this.subjectList.length)) {
        alert('Please select all the distributions of the defined subjects.');
        return;
      }
      else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$swal({
              title: 'Are you sure?',
              text: "You would like to submit this. After submission, you can't revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, submit it!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                let data = new FormData();
                data.append('marks', JSON.stringify(this.mark_distribution_array));
                apiCall.post('/mark/distribution/store', data).then((response) => {
                  this.clear();
                  this.$refs.simpleRules.reset();
                  if (response.data.status === 'success') {
                    this.$toaster.success(response.data.message);
                  } else this.$toaster.error(response.data.message);
                }).catch((error) => {
                  if (error.response.status == 422) {
                    Object.keys(error.response.data.errors).map((field) => {
                      this.$toaster.error(error.response.data.errors[field][0]);
                    })
                  } else this.$toaster.error(error.response.data.message);
                });
              }
            })
          }
        })
      }
    },
    clear() {
      this.classes_id = null;
      this.version_id = null;
      this.student_group_id = null;
      this.academic_year_id = null;
      this.exam_type_id = null;
      this.mark_types = [];
      this.mark_distribution_array = [];
    },
    /*method for get all classes*/
    async getClasses() {
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response) => {
        this.classes = response.data;
      }).catch(() => {
        this.classes = [];
      })
    },
    jsonDecode(data) {
      try {
        return JSON.parse(data);
      }
      catch (e) {
        return [];
      }
    },
    /*
        * methods for add new mark type or remove
        * */
    addNewMarkType(subjectId, markType) {

      if (document.getElementById(subjectId + '_grade').value == null || document.getElementById(subjectId + '_grade').value == "") {
        alert('Please select grade first');
        return;
      }

      if (this.mark_distribution_array.find(value => value.subject_id === subjectId && value.name === markType)) {
        this.mark_distribution_array.splice(this.mark_distribution_array.findIndex(value => value.subject_id === subjectId && value.name === markType), 1);
      } else {
        this.mark_distribution_array.push({
          'name': markType,
          'exam_type_id': this.exam_type_id,
          'academic_year_id': this.academic_year_id,
          'classes_id': this.classes_id,
          'subject_id': subjectId,
          'total_mark': 0,
          'pass_mark': 0,
          'mark_percent_add': 100,
          'created_at': this.currentDate(),
          'updated_at': this.currentDate(),
          'grade_id': document.getElementById(subjectId + '_grade').value
        });
      }
    },

  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['grade_types', 'academic_years', 'exam_types', 'subjects']),
    vs() {
      return this.$store.getters.versions;
    },
    cls() {
      return this.$store.getters.classes;
    },
    stg() {
      return this.$store.getters.student_groups;
    },
    exm_types() {

      var exam_type_list = this.$store.getters.exam_types.filter(item => {
        if (parseInt(item.is_active) === 1) {
          return item;
        }
      });

      return exam_type_list;
    },
    subjectList() {
      return this.$store.getters.subjects.filter(item => {
        if (parseInt(item.classes_id) === this.classes_id &&
          item.is_active == 1) {
          return item;
        }
      });

    },
  },
  created() {
    this.getClasses();
    if (this.grade_types.length < 1) {
      this.$store.dispatch('GET_ALL_GRADE_TYPE');
    }

    if (this.exam_types.length < 1) {
      this.$store.dispatch('GET_ALL_EXAM_TYPE');
    }

    if (this.academic_years.length < 1) {
      this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    }

    // if (this.vs.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    // if (this.cls.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    // if (this.stg.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.subjects.length < 1) this.$store.dispatch('GET_ALL_SUBJECT');

  },
  watch: {
    classes_id() {
      if (this.classes_id) {
        this.versions = [];
        this.student_groups = [];
        this.version_id = null;
        this.student_group_id = null;
        let cls = this.classes.find(item => parseInt(item.id) === this.classes_id);
        if (cls) {
          this.versions = cls.versions;
          this.student_groups = cls.student_groups;
        }
      } else {
        this.versions = [];
        this.version_id = null;
        this.student_group_id = null;
        this.student_groups = [];
      }
    },
    exam_type_id() {
      if (this.exam_type_id) {
        let typ = this.exam_types.find(item => parseInt(item.id) === this.exam_type_id);
        if (typ) {
          this.mark_types = this.jsonDecode(typ.mark_types);
        }
      }
      else { this.mark_types = []; }
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>