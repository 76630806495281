var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[(_vm.$can('read', 'Mark Distribution'))?_c('div',{staticClass:"custom-search d-flex"},[_c('router-link',{attrs:{"to":{ path: '/mark/distribution/update' }}},[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(" "+_vm._s(_vm.$t('Distribution List'))+" ")])],1)],1):_vm._e(),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"form_add_or_update",staticClass:"repeater-form",attrs:{"method":"post","action":"javascript:void(0)"},on:{"submit":_vm.submitMarkDistribution}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Class'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('class'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.classes_id),expression:"classes_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"classes_id","state":errors.length > 0 ? false : null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.classes_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.classes),function(cls,key){return _c('option',{key:key,domProps:{"value":cls.id}},[_vm._v(_vm._s(cls.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Academic Year'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('academic year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.academic_year_id),expression:"academic_year_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"academic_year_id","state":errors.length > 0 ? false : null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.academic_year_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.academic_years),function(acy,key){return _c('option',{key:key,domProps:{"value":acy.id}},[_vm._v(_vm._s(acy.year))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Exam type'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('exam type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.exam_type_id),expression:"exam_type_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"exam_type_id","state":errors.length > 0 ? false : null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.exam_type_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.exm_types),function(etype,key){return _c('option',{key:key,domProps:{"value":etype.id}},[_vm._v(_vm._s(etype.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_vm._l((_vm.subjectList),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Subject Name'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('subject name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('mark form')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", _vm._n($$v))},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Select Grade'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('class')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{staticClass:"form-control",attrs:{"id":item.id + '_grade',"state":errors.length > 0 ? false : null}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("Select Grade")]),_vm._l((_vm.grade_types),function(grade,key){return _c('option',{key:key,domProps:{"value":grade.id}},[_vm._v(_vm._s(grade.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Select Mark Type'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('mark type'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.mark_types),function(type,key){return _c('b-form-checkbox',{key:key,attrs:{"value":type,"name":"mark_type","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('mark type')},on:{"change":function($event){return _vm.addNewMarkType(item.id, type)}}},[_vm._v(_vm._s(type))])}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_vm._l((_vm.mark_distribution_array),function(inp,index2){return _c('b-row',{key:index2,ref:"row2",refInFor:true,staticStyle:{"padding":"15px"},attrs:{"id":'ss' + inp.id}},[(inp.subject_id === item.id)?[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Mark type'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('mark type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('mark type')},model:{value:(inp.name),callback:function ($$v) {_vm.$set(inp, "name", _vm._n($$v))},expression:"inp.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Total mark'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('total mark'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('total mark')},model:{value:(inp.total_mark),callback:function ($$v) {_vm.$set(inp, "total_mark", _vm._n($$v))},expression:"inp.total_mark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Pass mark'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('pass mark'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('pass mark')},model:{value:(inp.pass_mark),callback:function ($$v) {_vm.$set(inp, "pass_mark", _vm._n($$v))},expression:"inp.pass_mark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('To be added(TM×CM%)'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('mark percent add'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('mark percent add')},model:{value:(inp.mark_percent_add),callback:function ($$v) {_vm.$set(inp, "mark_percent_add", _vm._n($$v))},expression:"inp.mark_percent_add"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]:_vm._e()],2)})],2)}),_c('b-col',[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }